export default {
    init() {
        // JavaScript to be fired on all pages

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        $('.header__search__toggle').on('click', function () {
            $('.header__search').toggleClass('open')
            $('.header__search__input input').focus()
        })

        $('.menu-item.menu-contact a').replaceWith('<a href="#experts"><span>Contact</span></a>');

        if ($(window).width() < 768) {
            $('a[href*=\\#]').on('click', function(e){
                e.preventDefault();
                $('html,body').animate({scrollTop:$(this.hash).offset().top}, 'slow');
            });
        }
        else {
            $('a[href*=\\#]').on('click', function(e){
                e.preventDefault();
                $('html,body').animate({scrollTop: $(this.hash).offset().top - 100}, 'slow');
            });
        }


    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
